import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment'
import { BacktestStore } from "../../redux/reducers/backtesting/backtestingReducer";
import BellIcon from '@mui/icons-material/NotificationsActive'
import NotificationsNone from '@mui/icons-material/NotificationsNone'
import Check from '@mui/icons-material/Check'
import Clear from '@mui/icons-material/Clear'
import RepeatIcon from '@mui/icons-material/Repeat'
import Schedule from '@mui/icons-material/Schedule'
import NextRun from '@mui/icons-material/NextPlan'

import { blue, green, grey, orange } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import { TextWithIcon } from '../../components/TextWithIcon';
import { GenericTablet } from '../signalStats/GenericTablet';

interface BackTestResultRowProps
{
  row: BacktestStore
  isAdmin?: boolean
  onOpenItem: (item: BacktestStore) => void
}


export const CustomBackTestResultRow = ({ row, onOpenItem, isAdmin }: BackTestResultRowProps) =>
{
  return(
    <TableRow
            onClick={() => onOpenItem(row)}
            hover
             key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             { isAdmin && <TableCell component="th" scope="row">{row.username}</TableCell> }
              <TableCell component="th" scope="row">
                <div style={styles.avatarRow}>
                  <Avatar style={styles.avatar}>{[row.friendlyName[0].toUpperCase()]}</Avatar>
                  {row.friendlyName}
                </div>
                </TableCell>
              <TableCell align="right"><GenericTablet color={orange[500]} text={row.config?.tradingPair!}  /></TableCell>
              <TableCell align="right"><TextWithIcon icon={<RepeatIcon />} text={`every ${row.schedule} hours`} style={styles.rowWithIcon} /></TableCell>
              <TableCell align="right"><TextWithIcon icon={<Schedule />} text={row.lastRun ? moment(row.lastRun).fromNow() : "never"} style={styles.rowWithIcon} /></TableCell>
              <TableCell align="right"><TextWithIcon icon={<NextRun />} text={moment(row.nextRun) < moment() ? "In Queue" : moment(row.nextRun).fromNow()} style={styles.rowWithIcon} /></TableCell>
              <TableCell align="right">{row.config?.isTakeProfitEnabled ? `${row.config?.takeProfitPercentage}%` : "Not enabled"}</TableCell>
              <TableCell align="right">{row.config?.isStopLossEnabled ? `${row.config?.stopLossPercentage}%` : "Not enabled"}</TableCell>
              <TableCell align="right">{row.config?.takeProfitThreshold ? `${row.config?.takeProfitThreshold}%` : "Not enabled"}</TableCell>
              <TableCell align="right">{row.notifyAfterComplete ? <BellIcon htmlColor={green[400]} /> : <NotificationsNone htmlColor={grey[500]} />}</TableCell>
              <TableCell align="right">{row.isEnabled ? <Check htmlColor={green[400]} /> : <Clear htmlColor={grey[500]} />}</TableCell>
            </TableRow>
  )
}

const styles = {
  rowWithIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
  },
  avatarRow: {
    flex: 1,
    display: 'flex' as 'flex',
    flexDirection: "row" as "row",
    alignItems: 'center' as 'center',
  },
  avatar: {
    backgroundColor: blue[400],
    marginRight: 6,
    fontWeight: "700",
  }
}