import Grid from "@mui/material/Grid"
import { BacktestStore } from "../../redux/reducers/backtesting/backtestingReducer"
import { useEffect, useState } from "react"
import { BackTestApi } from "../../api/BackTestApi"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Avatar from "@mui/material/Avatar"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts"
import { SchedulePieChart } from "./SchedulePieChart"
import moment from "moment"
import { amber, blue, green, orange, purple, red } from "@mui/material/colors"



export const UserBacktestingStatsTab = () => {

    const [ gettingTests, setGettingTests ] = useState(false)
  
    const [ tests, setTests ] = useState<BacktestStore[]>([])

    useEffect(() => {
  
      getAllUserBacktestConfigs(true)
  
    }, [])

  const getAllUserBacktestConfigs = async (progress: boolean) => {

    if(progress) setGettingTests(true)
    try
    {
      const response = await new BackTestApi().getAllForUsers()
      if(response) setTests(response)
    }
    catch(error)
    {

    }

    if(progress) setGettingTests(false)

  }

  const calcStats = () => {

    let notifyAfterComplete = 0
    let enabledConfigs = 0
    let inQueue = 0
    let pending10Mins = 0
    let pendingHour = 0
    let pending4Hours = 0
    let schedule4h = 0;
    let schedule8h = 0;
    let schedule12h = 0;
    let schedule24h = 0;
    let schedule48h = 0;
    let scheduleWeek = 0;

    if(tests)
    {
      for(const config of tests)
      {
        if(config.isEnabled) enabledConfigs++
        if(config.nextRun < new Date().toISOString()) inQueue++
        if(config.nextRun > new Date().toISOString() && config.nextRun < moment().add(10, "minutes").toISOString()) pending10Mins++
        if(config.nextRun > new Date().toISOString() && config.nextRun < moment().add(1, "hour").toISOString()) pendingHour++
        if(config.nextRun > new Date().toISOString() && config.nextRun < moment().add(4, "hour").toISOString()) pending4Hours++

        if(config.notifyAfterComplete) notifyAfterComplete++
        if(config.schedule === 4) schedule4h++
        if(config.schedule === 8) schedule8h++
        if(config.schedule === 12) schedule12h++
        if(config.schedule === 24) schedule24h++
        if(config.schedule === 48) schedule48h++
        if(config.schedule === 168) scheduleWeek++
      }
    }

    return { totalConfigs: tests.length, enabledConfigs, inQueue, notifyAfterComplete, pending4Hours, pendingHour, pending10Mins, schedule4h, schedule8h, schedule12h, schedule24h, schedule48h, scheduleWeek }
  }

  const { totalConfigs, enabledConfigs, inQueue, notifyAfterComplete, pending10Mins, pending4Hours, pendingHour, schedule4h, schedule8h, schedule12h, schedule24h, schedule48h, scheduleWeek } = calcStats()


  return (
    <Grid container spacing={3}>

    <Grid item container direction="row" spacing={3}>
      <Grid item xs={6}>
          <Card sx={{ height: 300 }}>
            <CardHeader 
              titleTypographyProps={{ fontSize: 32 }}
              avatar={<Avatar style={{ fontSize: 100 }} sx={{ width: 275, height: 275 }}>{inQueue}</Avatar>} 
              title="Backtests in Queue">
            </CardHeader>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card sx={{ height: 300 }}>
            <SchedulePieChart scheduleWeek={scheduleWeek} schedule4h={schedule4h} schedule8h={schedule8h} schedule48h={schedule48h} schedule12h={schedule12h} schedule24h={schedule24h} />
          </Card>
        </Grid>

      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
        <Card >
          <CardHeader 
            avatar={<Avatar sx={{ width: 100, height: 100, bgcolor: amber[400] }}>{totalConfigs}</Avatar>} 
            title="Total configs">
          </CardHeader>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
        <Card>
          <CardHeader 
            avatar={<Avatar sx={{ width: 100, height: 100, bgcolor: blue[400] }}>{enabledConfigs}</Avatar>} 
            title="Enabled Configs">

          </CardHeader>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
        <Card>
          <CardHeader 
            avatar={<Avatar sx={{ width: 100, height: 100, bgcolor: green[400] }}>{notifyAfterComplete}</Avatar>} 
            title="Notifications Enabled">

          </CardHeader>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
        <Card>
          <CardHeader 
            avatar={<Avatar sx={{ width: 100, height: 100, bgcolor: red[400] }}>{pending10Mins}</Avatar>} 
            title="Pending Run"
            subheader="In the next 10 minutes">

          </CardHeader>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
        <Card>
          <CardHeader 
            avatar={<Avatar sx={{ width: 100, height: 100, bgcolor: orange[400] }}>{pendingHour}</Avatar>} 
            title="Pending Run"
            subheader="In the next hour">

          </CardHeader>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
        <Card>
          <CardHeader 
            avatar={<Avatar sx={{ width: 100, height: 100, bgcolor: purple[400] }} >{pending4Hours}</Avatar>} 
            title="Pending Run"
            subheader="In the next 4 hours">

          </CardHeader>
        </Card>
      </Grid>

    </Grid>
  )
}

const styles = {

}